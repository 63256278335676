import React, { createContext, useState, useEffect } from 'react';
import Client from 'shopify-buy';
import {get} from 'lodash';
import fetch from "isomorphic-fetch";

const client = Client.buildClient({
  domain: process.env.SHOPIFY_STORE_URL, // "leveluptutorialsstore.myshopify.com",
  storefrontAccessToken: process.env.SHOPIFY_ACCESS_TOKEN, // "e41e19f98903b8f577225487c40133ce",
},
fetch);

const defaultValues = {
  isCartOpen: false,
  toggleCartOpen: () => {},
  cart: [],
  addProductToCart: () => {},
  updateProductQuantity: () => {},
  removeProductFromCart: () => {},
  checkCoupon: () => {},
  client,
  checkout: {
    lineItems: [],
  },
};

export const StoreContext = createContext(defaultValues);

// Check if it's a browser
const isBrowser = typeof window !== "undefined"

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = useState(defaultValues.checkout);
  const [isCartOpen, setCartOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const toggleCartOpen = () => setCartOpen(!isCartOpen);

  useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem("checkout_id")
        : null;

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          );
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout);
            return;
          }
        } catch (e) {
          localStorage.setItem("checkout_id", null);
        }
      }

      const newCheckout = await client.checkout.create();
      setCheckoutItem(newCheckout);
    };

    initializeCheckout();
  }, []);

  const getNewId = async () => {
    console.group('getNewId');

    try {
      const newCheckout = await client.checkout.create();
      console.log(`isBrowser: ${isBrowser}`);
      if (isBrowser) {
        localStorage.setItem("checkout_id", newCheckout.id);
      }
      console.group('newCheckout');
      console.log(newCheckout);
      console.groupEnd();
      return newCheckout;
    } catch (e) {
      console.error(e);
    }
    console.groupEnd();

  };

  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem("checkout_id", checkout.id);
    }

    setCheckout(checkout);
  };

  const addProductToCart = async (variantId) => {
    console.group("addProductToCart");
    console.log(`checkout:`, checkout);
    try {
      setLoading(true);
      const lineItems = [
        {
          variantId,
          quantity: 1,
        },
      ];
      const newCheckout = await client.checkout.addLineItems(
        checkout.id,
        lineItems
      );
      // Buy Now Button Code
      // window.open(addItems.webUrl, "_blank")
      setCheckoutItem(newCheckout);
      // console.log(addItems.webUrl)
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
    console.groupEnd();
  };

  const updateProductQuantity = async (lineItemId, quantity) => {
    console.group("updateProductQuantity");
    console.log(`lineItemId: ${lineItemId}`);
    console.log(`quantity: ${quantity}`);
    try {
      setLoading(true);
      const newCheckout = await client.checkout.updateLineItems(
        checkout.id,
        [
          {
            id: lineItemId,
            quantity,
          },
        ]
      );
      setCheckoutItem(newCheckout);
      setLoading(false);

    } catch (e) {
      setLoading(false);
      console.error(e);
    }
    console.groupEnd();
  };

  const removeProductFromCart = async (lineItemId) => {
    try {
      setLoading(true);
      const newCheckout = await client.checkout.removeLineItems(checkout.id, [
        lineItemId,
      ]);
      setCheckoutItem(newCheckout);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const checkCoupon = async (coupon) => {
    setLoading(true);
    const newCheckout = await client.checkout.addDiscount(
      checkout.id,
      coupon
    );
    setCheckoutItem(newCheckout);
    setLoading(false);
  };

  const removeCoupon = async (coupon) => {
    setLoading(true);
    const newCheckout = await client.checkout.removeDiscount(
      checkout.id,
      coupon
    );
    setCheckout(newCheckout);
    setLoading(false);
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        checkout,
        addProductToCart,
        toggleCartOpen,
        isCartOpen,
        isLoading,
        updateProductQuantity,
        removeProductFromCart,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
